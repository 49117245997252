import { createSlice } from '@reduxjs/toolkit';

import { errorMessages } from 'components/ErrorModal/Constant';
import { RESET_ALL } from 'constants/enum';

const initialState = {
  isModalOpen: false,
  message: undefined
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setShowErrorModal: (state, action) => {
      state.isModalOpen = true;
      state.message = errorMessages?.[action.payload] || {
        title: action.payload?.data?.title || errorMessages.default.title,
        subtitle: action.payload?.data?.detail || errorMessages.default.subtitle
      };
    },
    setCloseErrorModal: (state) => {
      state.isModalOpen = false;
      state.message = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_ALL, () => initialState);
  }
});

export const { setCloseErrorModal, setInitialState, setShowErrorModal } =
  errorSlice.actions;

export default errorSlice.reducer;
