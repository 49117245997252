import {
  genderOptions,
  relationshipOptions
} from 'container/screens/AddOnInsurer/Constant';
import { occupationOptions } from 'container/screens/AddressOccupation/Constant';

export const vixResponseDecorator = (response) => {
  const applicationInformation = response?.application_information ?? {};
  const ncdDetails = response?.vix_response?.response?.ncd_details ?? {};
  const vehicleDetails =
    response?.vix_response?.response?.vehicle_details ?? {};
  return {
    applicationInformation,
    vehicleDetails: {
      ...vehicleDetails,
      ...ncdDetails
    }
  };
};

export const priceResponseDecorator = (response) => {
  return {
    ZURICH: response?.ZURICH,
    AIG: response?.AIG
  };
};

export const applicationInformationDecorator = (response) => {
  return {
    applicationId: response?.application_information?.application_id,
    idType: response?.application_information?.id_type,
    identificationNo: response?.application_information?.identification_no,
    vehicleRegistrationNumber:
      response?.application_information?.vehicle_registration_number,
    currentUser: response?.application_information?.current_user,
    lastStep: response?.application_information?.last_step,
    metadata: response?.application_information?.metadata,
    extra: response?.application_information?.extra,
    vixTransactionCode: response?.vix_response?.vix_transaction_code
  };
};

export const priceUpdateResponseDecorator = (response) => {
  const selectedAddOnsZurich = response?.ZURICH?.extra_coverage?.extra_block;
  const selectedAddOnsAig = response?.AIG?.extra_coverage?.extra_block;
  const mappedAddOnsZurich = selectedAddOnsZurich?.map((addOn) => {
    return {
      code: addOn[0].cover_code,
      availability: addOn[0].cover_availability,
      premium: addOn[0].cover_premium
    };
  });
  const mappedAddOnsAig = selectedAddOnsAig?.map((addOn) => {
    return {
      code: addOn[0].cover_code,
      availability: addOn[0].cover_availability,
      premium: addOn[0].cover_premium
    };
  });
  return {
    zurich: {
      addOns: mappedAddOnsZurich,
      zurichData: response?.ZURICH
    },
    aig: {
      addOns: mappedAddOnsAig,
      aigData: response?.AIG
    }
  };
};

export const addDriverDecorator = (drivers) => {
  return drivers.map((driver) => {
    return {
      Name: driver?.name,
      'ID Type': driver?.id_type,
      'ID Number': driver?.identity_no,
      Age: driver?.age,
      Gender: driver?.gender,
      'Marital Status': 'S',
      Relationship: driver?.relationship,
      DOB: driver?.dob,
      Occupation: driver?.occupation
    };
  });
};

const getLabelByValue = (options, value) => {
  return options.find((option) => option.value === value)?.label;
};
