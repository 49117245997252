export const defaultEnum = {
  mobileLayoutBreakpoint: 860,
  sst: 8
};

export const idLabels = {
  NRIC: 'IC',
  PASSPORT: 'PASSPORT',
  COMPANY_REGISTRATION_NUMBER: 'COMPANY REGISTRATION NUMBER',
  OTHERS: 'ARMY/POLICE/OTHERS'
};

export const RESET_ALL = 'resetAll';

export const RESET_LOADING = 'resetLoading';

export const RESET_PRICE = 'resetPrice';

export const UPDATE_VIX = 'updateVix';

export const ErrorTitle = {
  COVERNOTE: 'coverNoteError',
  NOTAVAILABLE: 'notAvailable',
  UPDATEPRICE: 'updatePriceError',
  EXCEEDTIME: 'exceedTime',
  DEFAULT: 'default',
  ADDONS: 'addOnsError',
  TERMSANDCONDITION: 'Kindly tick the checkbox before proceed to payment'
};

export const insurerType = {
  AIG: 'aig',
  ZURICH: 'zurich',
  QUOTATIONAIG: 'quotationNoAIG',
  QUOTATIONZURICH: 'quotationNoZurich',
  AIGPRICE: 'AIG',
  ZURICHPRICE: 'ZURICH'
};

export const buttonLabels = {
  BACK: 'Back',
  TRY_AGAIN: 'Try Again',
  GO_BACK: 'Go Back',
  START_OVER: 'Start Over',
  LEARN_MORE: 'Learn More',
  CHOOSE_PLAN: 'Choose Plan',
  NEXT: 'Next',
  TOTAL_AMOUNT: 'Total Amount',
  FREE_QUOTE: 'Get Free Quote',
  CLEAR_FIELDS: 'Clear fields',
  ADD_DRIVER: 'Additional Drivers',
  UPDATE_PRICE: 'Update Price',
  CONFIRM_PAY: 'Confirm & Pay',
  BACK_TO_HOME: 'Back to Home'
};

export const placeHolderLabels = {
  ADDRESS: 'Address Line 1, Address Line 2',
  SELECT: 'Please Select',
  CAR_REGISTRATION: 'ABC1234',
  ID_NUMBER: '881223102332',
  PASSPORT: 'A00000000',
  COMPANY: '881001102332',
  OTHERS: '881001102332'
};

export const tipLabels = {
  SUM_INSURED:
    'This is the Agreed Value that insurers will compensate you in full in the event of a total loss or theft claim',
  REMINDER:
    'Please ensure that all details that you have provided are correct and accurate to avoid any problems when making a claim',
  FOOTNOTE:
    ' By clicking “Get Free Quote” button, you have read and agreed to our',
  PRIVACY_POLICY: 'Privacy Policy',
  TERMSANDCONDITION:
    "I confirm that the details above are true and accurate and I've read and understood the Terms and Conditions and the Privacy Policy and allow my information to be used solely for the purpose of this application."
};

export const priceLabels = {
  NOTAVAILABLE: 'N/A'
};

export const headerLabels = {
  PERSONAL_DETAILS: 'Personal Details',
  AUTO_INSURANCE: 'Auto Insurance',
  GET_INSTANT_QUOTE: 'Get Instant Quote',
  COMPARE_CAR_INSURANCE:
    'Compare car insurance and get instant quote within 1 minute',
  SIME_DARBY: 'Sime Darby',
  COVERAGE_AMOUNT: 'Coverage Amount',
  EXCLUSIVE_BMW_MINI: 'Exclusive for BMW and MINI',
  CHOOSE_ADDONS: 'Choose Add-ons',
  TITLE_ZURICH: 'Zurich Z-Driver',
  TITLE_AIG: 'AIG',
  PAYMENT_SUCCESS: 'Thank you for your payment.',
  PAYMENT_FAILED: 'Sorry, it seems your payment didn’t go through.',
  RECEIVE_EMAIL: 'You’ll receive e-mail confirmation shortly.',
  YOUR_ORDER: 'Your order ID :',
  DISTRIBUTED_BY: 'Distributed by Sime Darby Auto Bavaria Sdn. Bhd.',
  CUSTOMER_SERVICE:
    'Please contact our customer service at 1-300-13-3338 for assistance.',
  GENERATE_COVER_NOTE_FAIL: 'We couldn’t generate your cover note',
  UNDERWRITTEN_BY: 'Underwritten by ',
  INSURANCE_DETAILS: 'Insurance Details'
};

export const coverNoteLabels = {
  ZURICH: 'Zurich General Insurance Malaysia Berhad',
  AIG: 'AIG Malaysia Insurance Berhad'
};

export const coverageCodesWithSumInsured = ['C002', 'C003', 'C004'];

export const preselectedAddOns = ['C001', 'C004', 'C006'];

export const toExcludeFieldsByModel = ['BMW', 'MINI'];

export const validationLabels = {
  PASSPORT:
    'Passport must be between 5-20 characters and can contain numerical, alphabetical, and (-) dash symbol character(s) only.',
  COVERAGE: 'Minimum base of coverage is RM 2,000'
};

export const recaptchaLabel = {
  ERROR: 'Please complete the reCAPTCHA',
  SUCCESS: '',
  FAILED: 'Failed to load reCAPTCHA',
  EXPIRED: 'reCAPTCHA has expired'
};

export const transactionStatus = {
  SUCCESSFUL: 'SUCCESSFUL',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  PRE_AUTHORIZED: 'PRE_AUTHORIZED',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  PENDING: 'PENDING'
};
