/** @jsxImportSource theme-ui */
import React, {
  createContext,
  lazy,
  Suspense,
  useContext,
  useState
} from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { Themes } from './styles';
import setupStore from 'store/store';
import { ThemeProvider } from 'theme-ui';

const Screens = lazy(() => import('container/screens'));
const store = setupStore();

export const EditUIContext = createContext();

export const useEditUiContext = () => useContext(EditUIContext);

export const EditUiProvider = ({ children }) => {
  const [editUi, setEditUi] = useState(false);
  const toggleEditUi = () => setEditUi(!editUi);
  return (
    <EditUIContext.Provider value={{ editUi, toggleEditUi }}>
      {children}
    </EditUIContext.Provider>
  );
};

function App() {
  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={Themes.MainTheme}>
        <EditUiProvider>
          <Provider store={store}>
            <Router>
              <Screens />
            </Router>
          </Provider>
        </EditUiProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
