import { createSlice } from '@reduxjs/toolkit';

import { RESET_ALL } from 'constants/enum';
import { Services } from 'services/api/services';
import { vixResponseDecorator } from 'services/decorators/responses';
import { exceptionModels } from 'util/helper';

const initialState = {
  editMode: false,
  routes: [
    '/',
    '/personal-details',
    '/coverage-suminsured',
    '/choose-plan',
    '/choose-addon',
    '/address-occupation',
    '/summary',
    '/thank-you'
  ],
  queryStrings: {
    applicationId: undefined,
    quotationNoAIG: undefined,
    quotationNoZurich: undefined
  },
  insurer: undefined,
  isModalOpen: undefined,
  showModalMobile: undefined,
  isBmwOrMini: undefined,
  isLoading: {
    value: undefined,
    refresh: undefined
  },
  tokenExpired: false
};

export const baseReducer = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setShowModalMobile: (state, action) => {
      state.showModalMobile = action.payload;
    },
    setTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
    setEditMode: (state, action) => {
      const { value } = action.payload;
      state.editMode = value;
    },
    setQueryStrings: (state, action) => {
      state.queryStrings = action.payload;
    },
    setRoutes: (state, action) => {
      const { value } = action.payload;
      state.routes = value;
    },
    setInsurer: (state, action) => {
      state.insurer = action.payload;
    },
    setOpenModal: (state) => {
      state.isModalOpen = true;
    },
    setCloseModal: (state) => {
      state.isModalOpen = false;
    },
    setLoading: (state, action) => {
      state.isLoading.value = action.payload?.value;
      state.isLoading.refresh = action.payload?.refresh;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET_ALL, () => initialState)
      .addMatcher(
        Services.endpoints.checkInsuranceEligibility.matchFulfilled,
        (state, { payload }) => {
          const { applicationInformation, vehicleDetails } =
            vixResponseDecorator(payload);
          state.queryStrings = {
            applicationId: applicationInformation?.application_id
          };
          state.isBmwOrMini = exceptionModels(vehicleDetails?.vehicle_model);
        }
      )
      .addMatcher(
        Services.endpoints.getVehicleInformation.matchFulfilled,
        (state, { payload }) => {
          const { applicationInformation, vehicleDetails } =
            vixResponseDecorator(payload);
          state.queryStrings = {
            applicationId: applicationInformation?.application_id
          };
          state.isBmwOrMini = exceptionModels(vehicleDetails?.vehicle_model);
          if (
            applicationInformation?.extra?.insurer_code?.split(',')?.length > 1
          ) {
            applicationInformation.extra.insurer_code =
              applicationInformation.extra.insurer_code.split(',')[0];
          }
          state.insurer =
            applicationInformation?.extra?.insurer_code?.toLowerCase();
        }
      )
      .addMatcher(
        Services.endpoints.chooseSumInsured.matchFulfilled,
        (state, { payload }) => {
          const { response } = payload;
          if (response?.ZURICH?.quotation_no) {
            state.queryStrings = {
              ...state.queryStrings,
              quotationNoZurich: response?.ZURICH?.quotation_no
            };
          }
          if (response?.AIG?.quotation_no) {
            state.queryStrings = {
              ...state.queryStrings,
              quotationNoAIG: response?.AIG?.quotation_no
            };
          }
        }
      )
      .addMatcher(
        Services.endpoints.updateSelectedAddOns.matchFulfilled,
        (state, { payload }) => {
          const { response } = payload;
          if (response?.ZURICH?.quotation_no) {
            state.queryStrings = {
              ...state.queryStrings,
              quotationNoZurich: response?.ZURICH?.quotation_no
            };
          }
          if (response?.AIG?.quotation_no) {
            state.queryStrings = {
              ...state.queryStrings,
              quotationNoAIG: response?.AIG?.quotation_no
            };
          }
        }
      );
  }
});

export const {
  setCloseModal,
  setEditMode,
  setInsurer,
  setLoading,
  setOpenModal,
  setQueryStrings,
  setRoutes,
  setShowModalMobile,
  setTokenExpired
} = baseReducer.actions;

export default baseReducer.reducer;
