import { createSlice } from '@reduxjs/toolkit';

import { RESET_ALL } from 'constants/enum';
import { Services } from 'services/api/services';
import { vixResponseDecorator } from 'services/decorators/responses';

const initialState = {};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationData: (state, action) => {
      return { ...state, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET_ALL, () => initialState)
      .addMatcher(
        Services.endpoints.checkInsuranceEligibility.matchFulfilled,
        (state, { payload }) => {
          const { applicationInformation } = vixResponseDecorator(payload);
          return applicationInformation;
        }
      )
      .addMatcher(
        Services.endpoints.getVehicleInformation.matchFulfilled,
        (state, { payload }) => {
          const { applicationInformation } = vixResponseDecorator(payload);
          return applicationInformation;
        }
      );
  }
});

export const { setApplicationData } = applicationSlice.actions;

export default applicationSlice.reducer;
