import { createSlice } from '@reduxjs/toolkit';

import { Fields } from '../Constant';
import { idLabels, RESET_ALL, UPDATE_VIX } from 'constants/enum';

export const initialState = {
  [Fields.CAR_NUMBER]: undefined,
  [Fields.ID_TYPE]: idLabels.NRIC,
  [Fields.ID_NUMBER]: undefined,
  [Fields.DOB]: undefined,
  [Fields.AGE]: undefined,
  [Fields.GENDER]: undefined,
  data: undefined
};

export const landingPageSlice = createSlice({
  name: 'vehicleIdNumber',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setExtractedDataFromICNumber: (state, action) => {
      const { data, status } = action.payload;
      if (status === 'SUCCESS') {
        state[Fields.ID_NUMBER] = data.ICNumber;
        state[Fields.DOB] = `${data.Year}-${data.Month}-${data.Day}`;
        state[Fields.AGE] = data.Age;
        state[Fields.GENDER] = data.Gender;
      } else {
        state[Fields.ID_NUMBER] = data.ICNumber;
      }
    },
    resetCarState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET_ALL, () => initialState)
      .addCase(UPDATE_VIX, (state, action) => {
        const data = action.payload;
        state[Fields.CAR_NUMBER] = data?.vehicle_registration_number;
        state[Fields.ID_NUMBER] = data?.identification_no;
        state[Fields.ID_TYPE] = data?.id_type;
        if (data?.extra?.dob) {
          state[Fields.DOB] = data?.extra?.dob;
          state[Fields.AGE] = data?.extra?.age;
          state[Fields.GENDER] = data.extra.gender;
        }
      });
  }
});

export const { resetCarState, setExtractedDataFromICNumber, setFieldValue } =
  landingPageSlice.actions;

export default landingPageSlice.reducer;
