import { createSlice } from '@reduxjs/toolkit';

import { Fields } from '../Constant';
import { RESET_ALL, UPDATE_VIX } from 'constants/enum';
import { Services } from 'services/api/services';
import { capitalizeWords } from 'util/helper';

export const initialState = {
  [Fields.RESIDENTIAL_ADDRESS]: undefined,
  [Fields.OCCUPATION]: undefined,
  [Fields.COMPANY_ADDRESS]: undefined
};

export const AddressOccupationSlice = createSlice({
  name: 'addressOccupation',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET_ALL, () => initialState)
      .addCase(UPDATE_VIX, (state, action) => {
        state[Fields.RESIDENTIAL_ADDRESS] = action.payload?.extra?.address;
        state[Fields.OCCUPATION] = action.payload?.extra?.occupation;
        state[Fields.COMPANY_ADDRESS] = action.payload?.extra?.company_address;
      });
  }
});

export const { setFieldValue } = AddressOccupationSlice.actions;

export default AddressOccupationSlice.reducer;
