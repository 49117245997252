export const detailBMW = [
  {
    label: 'Waiver of Betterment(up to 10 years)',
    value: 0
  },
  { label: 'All Drivers', value: 0 },
  { label: 'Key Replacement', displayCoverage: 2000, value: 0 },
  { label: 'Window Snatch Theft', displayCoverage: 2000, value: 0 },
  { label: 'Compassionate Flood Allowance', displayCoverage: 2000, value: 0 },
  {
    label: 'Personal Accident Cover for Insured Only',
    displayCoverage: 20000,
    value: 0
  }
];

export const Fields = {
  NAME: 'Name',
  ID_TYPE: 'ID Type',
  ID_NUMBER: 'ID Number',
  MARITAL_STATUS: 'Marital Status',
  RELATIONSHIP: 'Relationship',
  AGE: 'Age',
  GENDER: 'Gender',
  DOB: 'DOB',
  DATE_OF_BIRTH: 'Date of Birth',
  OCCUPATION: 'Occupation'
};

export const coverageCodeMapping = {
  C001: 'All Drivers',
  C002: 'Legal Liability to Passengers',
  C003: 'Strike, Riot and Civil Commotion',
  C004: 'Special Peril Coverage',
  C005: 'Legal Liability of Passengers for Negligent Acts',
  C006: 'Cover for Windscreens, Windows and Sunroof',
  C012: 'Additional Drivers'
};

export const idOptions = [
  { label: 'NRIC/MyKad', value: 'IC' },
  { label: 'Passport', value: 'PASSPORT' },
  { label: 'Army/Police/Others', value: 'ARMY/POLICE/OTHERS' },
  {
    label: 'Company Registration Number',
    value: 'COMPANY REGISTRATION NUMBER'
  }
];

export const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' }
];

export const relationshipOptions = [
  { label: 'Parent', value: '01' },
  { label: 'Spouse', value: '02' },
  { label: 'Child', value: '03' },
  { label: 'Sibling/Cousin/Relative', value: '04' }
];

export const newDriver = {
  Age: undefined,
  DOB: undefined,
  Gender: undefined,
  'ID Number': undefined,
  'ID Type': undefined,
  'Marital Status': 'S',
  Name: undefined,
  Occupation: '01',
  Relationship: undefined
};
