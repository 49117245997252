import { toExcludeFieldsByModel } from 'constants/enum';

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const locationPathNameToReduxReducerName = (pathName) => {
  return camelize(pathName.replace('/', '').replace(/-/g, ' '));
};

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeWords = (value) => {
  if (!value) return value;

  return value
    .split(',')
    .map((words) =>
      words
        .trim()
        .split(/\s+/)
        .map((word) => capitalizeFirstLetter(word))
        .join(' ')
    )
    .join(', ');
};

export const snakeCase = (string, shouldUpperCase) => {
  return string
    .replace(/\d+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => (shouldUpperCase ? word.toUpperCase() : word.toLowerCase()))
    .join('_');
};

export const validator = {
  email: (email) => /^([\w.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/g.test(email),
  mobile: (number) => {
    const status = true;
    if (!number.startsWith('1', 0)) {
      return false;
    }
    if (!(number.length === 9 || number.length === 10)) {
      return false;
    }
    return status;
  }
};

export const isNullOrUndefined = (value) => {
  return value === undefined || value === null;
};

export const isEmpty = (value) =>
  value === undefined || value === null || value === '';

export const dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const getAgeFromDOB = (dob) => {
  const todaysDate = new Date();
  return Math.floor(dateDiffInDays(dob, todaysDate) / 365.25);
};

export const decorateDataForDropDowns = (data, key = '') => {
  if (data && typeof data === 'object') {
    return data.map((item) => {
      if (typeof item === 'object') {
        return {
          label: item.label,
          value: item.value?.toString()
        };
      } else {
        return {
          label: item.toString(),
          value: item.toString()
        };
      }
    });
  }
  return data;
};

export const extractKeyOfObjectAsDropDownValue = (data) => {
  if (data && typeof data === 'object') {
    return Object.keys(data).map((item) => {
      return { label: item.toString(), value: item.toString() };
    });
  }
  return [];
};

export const getHTMLDots = (n) => '\u{25CF}'.repeat(n);

export const concatString = (pre = '', post = '') => {
  if (isEmpty(pre) || isEmpty(post)) return;
  return `${pre}${post}`;
};

export const pageAccessedByReload = () => {
  if (
    window.performance.navigation &&
    window.performance.navigation.type === 1
  ) {
    return window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload');
  }
};

export const addCommas = (value) =>
  value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const mobileFormat = (mobileNumber, countryCode) => {
  if (!mobileNumber) return '';
  switch (countryCode) {
    case '60':
      return `+60-${mobileNumber}`;
    case '65':
      return `+65-${mobileNumber.slice(0, 4)}-${mobileNumber.slice(4)}`;
    case '66':
      return `+66-${mobileNumber.slice(0, 2)}-${mobileNumber.slice(
        2,
        5
      )}-${mobileNumber.slice(5)}`;
    case '673':
      return `+673-${mobileNumber.slice(0, 3)}-${mobileNumber.slice(3)}`;
    default:
      return `+${countryCode}-${mobileNumber}'
      )}`;
  }
};

export const phoneSample = (countryCode) => {
  switch (countryCode) {
    case '60':
      return '123456789';
    case '65':
      return '87654321';
    case '66':
      return '987654321';
    case '673':
      return '7654321';
  }
};

export const truncateString = (str, size) => {
  if (str.length > (size || 25)) {
    return `${str.slice(0, size || 25)}...`;
  } else {
    return str;
  }
};

export const convertSecondsToMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60);

  const remainingSeconds = seconds % 60;
  return `${minutes < 10 ? '0' : ''}${minutes}:${
    remainingSeconds < 10 ? '0' : ''
  }${remainingSeconds}`;
};

export const convertToSearchParams = (queryObject) => {
  if (!queryObject) {
    return '';
  }

  const newQueryObject = Object.fromEntries(
    Object.entries(queryObject).filter(([key, value]) => value !== undefined)
  );

  return `?${new URLSearchParams(newQueryObject).toString()}`;
};

export const remCalc = (px, base = 16) => {
  if (typeof px === 'object') {
    return px;
  }
  if (typeof px === 'number' || px?.includes('px')) {
    // let tempPx = px;
    try {
      return `${parseFloat((px / base).toFixed(2))}rem`;
    } catch (e) {
      return px;
    }
    // tempPx = parseInt(tempPx);
    // return (1 / base) * tempPx + 'rem';
  } else return px;
};

export const formatNumber = (input) => {
  if (input === null || input === undefined || input === '') {
    return 0;
  }
  const number =
    typeof input === 'string' ? Number(input.replace(/,/g, '')) : input;

  return !isNaN(number)
    ? number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })
    : 0;
};

export const formatHtmlTagId = (string) => {
  if (!string) return;
  // Convert to lowercase and replace non-alphanumeric characters with dashes
  const slug = string.toLowerCase().replace(/[^a-z0-9]+/g, '_');
  // Remove leading and trailing dashes
  return slug.replace(/^-+|-+$/g, '');
};

export const getExpectedDateTime = (expiryMinutes = null) => {
  const now = new Date();
  now.setMinutes(now.getMinutes() + expiryMinutes);
  const gmt8Time = new Date(
    now.getTime() + now.getTimezoneOffset() * 60000 + 8 * 60 * 60000
  );
  const pad = (num) => num.toString().padStart(2, '0');
  const year = gmt8Time.getFullYear();
  const month = pad(gmt8Time.getMonth() + 1);
  const day = pad(gmt8Time.getDate());
  const hours = pad(gmt8Time.getHours());
  const minutes = pad(gmt8Time.getMinutes());
  const seconds = pad(gmt8Time.getSeconds());

  return `${day}${month}${year}${hours}${minutes}${seconds}`;
};

export const getCurrentDateFormat = (dateString = null) => {
  const currentDate = new Date(dateString);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDDMMYYYYFromISO = (date) => {
  if (!date) return;
  const dateElements = date.split('-');
  return `${dateElements[2]}-${dateElements[1]}-${dateElements[0]}`;
};

export const logError = (error, message = '') => {
  const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';

  if (isDevelopment) {
    console.error('Error:', error, message);
  }
};

export const isURL = (url) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const trimValue = (value) => {
  return value?.replace(/\s+/g, '');
};

export const exceptionModels = (carModel) => {
  return toExcludeFieldsByModel.some((model) =>
    carModel?.toUpperCase().includes(model)
  );
};

export const upperCase = (text) => {
  return text?.toUpperCase();
};
