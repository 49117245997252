import { configureStore } from '@reduxjs/toolkit';

import ApplicationReducer from './state/applicationSlice';
import BaseReducer from './state/baseSlice.js';
import OwnerReducer from './state/ownerSlice';
import PriceREducer from './state/pricingSlice';
import VehicleReducer from './state/vehicleSlice';
import StepperReducer from 'components/Stepper-POC/Logic/Stepper.reducer.js';
import AddOnAIGReducer from 'container/screens/AddOnAIG/Logic/AddOnAIG.reducer';
import AddOnZurichReducer from 'container/screens/AddOnZurich/Logic/AddOnZurich.reducer';
import AddressOccupationReducer from 'container/screens/AddressOccupation/Logic/AddressOccupation.reducer';
import CoverageSumInsuredReducer from 'container/screens/CoverageSumInsured/Logic/CoverageSumInsured.reducer';
import LandingPageReducer from 'container/screens/LandingPage/Logic/LandingPage.reducer';
import PersonalDetailsReducer from 'container/screens/PersonalDetails/Logic/PersonalDetails.reducer';
import { Auth } from 'services/api/auth.js';
import { Services } from 'services/api/services';
import ErrorReducer from 'store/state/errorSlice';

const setupStore = (preloadedState) => {
  return configureStore({
    reducer: {
      error: ErrorReducer,
      base: BaseReducer,
      stepper: StepperReducer,
      vehicleIdNumber: LandingPageReducer,
      personalDetails: PersonalDetailsReducer,
      coverageSumInsured: CoverageSumInsuredReducer,
      addOnZurich: AddOnZurichReducer,
      addOnAIG: AddOnAIGReducer,
      addressOccupation: AddressOccupationReducer,
      application: ApplicationReducer,
      vehicle: VehicleReducer,
      owner: OwnerReducer,
      price: PriceREducer,
      [Services.reducerPath]: Services.reducer,
      [Auth.reducerPath]: Auth.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(Services.middleware, Auth.middleware),
    preloadedState
  });
};

export default setupStore;
