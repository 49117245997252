import { createSlice } from '@reduxjs/toolkit';

import { RESET_ALL } from 'constants/enum';

const initialState = {
  currentStep: '',
  isAllowToNext: true,
  isAllowToPrev: true,
  isShowStepper: true
};

export const StepperReducer = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      const { currentStep } = action.payload;
      state.currentStep = currentStep;
    },
    disableNextButton: (state) => {
      state.isAllowToNext = false;
    },
    enableNextButton: (state) => {
      state.isAllowToNext = true;
    },
    disablePrevButton: (state) => {
      state.isAllowToPrev = false;
    },
    enablePrevButton: (state) => {
      state.isAllowToPrev = true;
    },
    hideStepper: (state) => {
      state.isShowStepper = false;
    },
    showStepper: (state) => {
      state.isShowStepper = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_ALL, () => initialState);
  }
});

export const {
  disableNextButton,
  disablePrevButton,
  enableNextButton,
  enablePrevButton,
  hideStepper,
  setCurrentStep,
  showStepper
} = StepperReducer.actions;

export default StepperReducer.reducer;
