import { createSlice } from '@reduxjs/toolkit';

import { RESET_ALL } from 'constants/enum';

const initialState = {
  name: null,
  mobile_no: null,
  email: null,
  marital_status: null,
  postcode: null
};

const ownerSlice = createSlice({
  name: 'owner',
  initialState,
  reducers: {
    setOwnerDetails: (state, action) => {
      state.name = action.payload.name;
      state.mobile_no = action.payload.mobile_no;
      state.email = action.payload.email;
      state.marital_status = action.payload.marital_status;
      state.postcode = action.payload.postcode;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_ALL, () => initialState);
  }
});

export const { setOwnerDetails } = ownerSlice.actions;

export default ownerSlice.reducer;
