export const Fields = {
  COVERAGE_TYPE: 'Coverage Type',
  SUM_INSURED: 'Sum Insured'
};

export const CoverageTypes = {
  COMPREHENSIVE: {
    label: 'Comprehensive',
    value: 'V-CO'
  },
  THIRD_PARTY_FIRE_THEFT: {
    label: 'Third Party, Fire & Theft',
    value: 'V-FT'
  }
};
