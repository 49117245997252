import { logError } from 'util/helper';

export const saveCookie = (key, value, options = {}) => {
  const expires = new Date();
  // expiration date - 1 day
  expires.setTime(expires.getTime() + (options.days || 1) * 864e5);

  let cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
    JSON.stringify(value)
  )}`;
  cookie += `; expires=${expires.toUTCString()}`;
  cookie += `; path=${options.path || '/'}`;
  cookie += '; SameSite=Lax';
  if (options.secure) {
    cookie += '; Secure';
  }

  document.cookie = cookie;
};

export const getCookie = (key) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${encodeURIComponent(key)}=`);
  if (parts.length === 2) {
    try {
      return JSON.parse(decodeURIComponent(parts.pop().split(';').shift()));
    } catch (error) {
      logError(error, 'Could not parse the cookie value');
      return null;
    }
  }
  return null;
};

export const clearCookie = (key, path = '/') => {
  document.cookie = `${encodeURIComponent(
    key
  )}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax`;
};
