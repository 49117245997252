export const Fields = {
  RESIDENTIAL_ADDRESS: 'Residential Address',
  COMPANY_ADDRESS: 'Company Address',
  POSTCODE: 'Postcode',
  CITY_TOWN: 'City/Town',
  OCCUPATION: 'Occupation',
  STATE: 'State'
};

export const occupationOptions = [
  { label: 'Office Worker', value: '01' },
  {
    label: 'Sales or Service Personnel (Not In Office)',
    value: '03'
  },
  {
    label: 'Other Professionals (Doctors, Accountants, Etc.)',
    value: '04'
  },
  { label: 'Unemployed', value: '05' },
  { label: 'Homemakers', value: '06' },
  {
    label: 'Professional Drivers',
    value: '07'
  },
  { label: 'Students', value: '08' },
  { label: 'Retired Person', value: '09' },
  { label: 'Uniformed Personnel', value: '10' },
  { label: 'Others', value: '99' }
];
