import { createApi } from '@reduxjs/toolkit/query/react';

import { saveCookie } from 'util/cookieStorage';

const fetchWithTokenHandling = async (args) => {
  const response = await fetch(args.url, {
    method: args.method,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: args.body
  });

  const currentTime = Date.now() / 1000;

  const data = await response.json();
  saveCookie('accessToken', data.access_token);
  saveCookie('refreshToken', data.refresh_token);
  saveCookie('expiresIn', currentTime + data.expires_in);
  saveCookie('refreshExpiresIn', currentTime + data.refresh_expires_in);

  return { data };
};

export const Auth = createApi({
  reducerPath: 'Auth',
  endpoints: (builder) => ({
    initialTokenFetch: builder.mutation({
      queryFn: () =>
        fetchWithTokenHandling({
          url: `${process.env.REACT_APP_KEYCLOAK_REALMS_PATH}/token`,
          method: 'POST',
          body: new URLSearchParams({
            client_id: process.env.REACT_APP_CLIENT_ID,
            grant_type: 'password',
            username: process.env.REACT_APP_ADMIN_USERNAME,
            password: process.env.REACT_APP_ADMIN_PASSWORD,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
          })
        })
    }),
    refreshToken: builder.mutation({
      queryFn: (refreshToken) =>
        fetchWithTokenHandling({
          url: `${process.env.REACT_APP_KEYCLOAK_REALMS_PATH}/token`,
          method: 'POST',
          body: new URLSearchParams({
            client_id: process.env.REACT_APP_CLIENT_ID,
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
          })
        })
    })
  })
});

export const { useInitialTokenFetchMutation, useRefreshTokenMutation } = Auth;

export const { initialTokenFetch, refreshToken } = Auth.endpoints;
