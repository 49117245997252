export const Fields = {
  COMPANY_NAME: 'Company Name',
  FULL_NAME: 'Full Name',
  DOB: 'Date of Birth',
  GENDER: 'Gender',
  MOBILE_NUMBER: 'Phone Number',
  EMAIL: 'Email',
  MARITAL_STATUS: 'Marital Status',
  POSTCODE: 'Postcode',
  STATE: 'State',
  CITY: 'City',
  OCCUPATION: 'Occupation'
};

export const maritalStatusOptions = [
  { label: 'Single', value: 'S' },
  { label: 'Married', value: 'M' },
  { label: 'Widowed', value: 'W' },
  { label: 'Divorced', value: 'D' }
];

export const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' }
];
