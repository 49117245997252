// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'DM Sans', sans-serif !important;
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.react-date-picker__wrapper {
  border: 1px solid #D6D6DA !important;
  border-radius: 4px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.react-date-picker__wrapper input::placeholder {
  color: #AAB0BC;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAEA;EACE,6CAA6C;EAC7C,SAAS;EACT,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,qCAAqC;AACvC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');\n\nbody {\n  font-family: 'DM Sans', sans-serif !important;\n  margin: 0;\n  overflow-y: hidden;\n  overflow-x: hidden;\n}\n\n.react-date-picker__wrapper {\n  border: 1px solid #D6D6DA !important;\n  border-radius: 4px;\n  height: 40px;\n  padding-left: 12px;\n  padding-right: 12px;\n}\n\n.react-date-picker__wrapper input::placeholder {\n  color: #AAB0BC;\n}\n\n.ReactModal__Overlay {\n  opacity: 0;\n  transition: opacity 500ms ease-in-out;\n}\n\n.ReactModal__Overlay--after-open {\n  opacity: 1;\n}\n\n.ReactModal__Overlay--before-close {\n  opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
