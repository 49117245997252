import { defaultEnum } from 'constants/enum';

export const MainTheme = {
  borders: {
    borderGrey: '1px solid #D6D6DA',
    thin: '1px solid var(--theme-ui-colors-text, black)'
  },
  breakpoints: [
    `${defaultEnum.mobileLayoutBreakpoint}px`,
    `${defaultEnum.mobileLayoutBreakpoint}px`,
    '1500px',
    '1720px'
  ],
  colors: {
    AP1: '#1758B6',
    AP2: '#1758B6',
    AP2Disabled: '#1758B6',
    APLight: 'rgba(180, 30, 142, 0.08)',
    APLighter: 'rgba(180, 30, 142, 0.05)',
    backgroundDisabled: '#F0F0F0',
    backgroundOnboarding: 'rgba(255, 255, 255, 0.60)',
    backgroundPrimary: '#31314466',
    backgroundType: '#1758B626',
    borderField: '#BDBDBD',
    danger: '#F00E00',
    darkGrey: '#313144',
    darkGreySecondary: '#6D6F71',
    disabledGrey: '#8A8D8F',
    disabledWhite: '#FAFAFA',
    dropdownOptionColor: 'rgba(51, 51, 51, 0.8)',
    gradientYellow: 'linear-gradient(180deg, #FBD960 28.29%, #C89C00 114.47%)',
    grey100: '#A2A8B3',
    grey200: '#B694FF1A',
    grey300: '#F9F9F9',
    grey400: '#A2A8B2',
    grey50: '#D6D6DA',
    greyText: '#5A5A69',
    hoverOptionColor: '#F2F9FC',
    info: '#318CE7',
    lightGrey: '#CCC',
    lightPink: 'rgba(180, 30, 142, 0.15)',
    lightText: '#263238',
    lighterGrey: 'rgba(249, 249, 249, 0.70)',
    linearChoosePlan:
      'linear-gradient(226deg, rgba(137, 203, 223, 0.10) 31.06%, rgba(233, 250, 255, 0.10) 100%)',
    linearHorizontal:
      'linear-gradient(223deg, #1758B6 0%, #2D6EBE 46.88%, #89CBDF 100%)',
    linearHorizontal2: 'linear-gradient(223deg, #F8F8F8 0%, #FFF 100%)',
    linearSimeDarby:
      'linear-gradient(196deg, #FBAE17 0%, #ED1C24 50.32%, #C4161C 100%)',
    linearVertical: 'linear-gradient(180deg, #1758B6 0%, #124691 100%)',
    neutral: '#E7EEF7',
    offWhite: '#F9F9F9',
    placeholder: '#AAB0BC',
    plainWhite: '#FFFFFF',
    primary: '#1758B6;',
    secondary: '#231F20',
    success: '#12AB3D',
    text: '#231F20'
  },
  fontSizes: [10, 12, 14, 16, 18, 20, 24, 26, 32, 36],
  fontWeights: {
    body: 400,
    bold: 700,
    heading: 700,
    light: 300,
    lighter: 200,
    medium: 500,
    regular: 400,
    semiBold: 600,
    title: 600
  },
  fonts: {
    body: 'DM Sans, system-ui, sans-serif',
    heading: 'DM Sans, system-ui, sans-serif',
    monospace: 'DM Sans, system-ui, sans-serif'
  },
  letterSpacings: {
    normal: '0px',
    tight: '-0.4px',
    wide: '0.4px',
    wider: '0.8px',
    widest: '1.6px'
  },
  lineHeights: {
    body: 1.5,
    caption: 1.1,
    heading: 1.25,
    hero: 2.0,
    loose: 1.8,
    tight: 1.2
  },
  margin: ['1'],
  shadows: {
    background: '0px 8px 20px #5A5A69',
    basic: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    boxShadow: '0px 4px 20px 0px rgba(35, 31, 32, 0.3)',
    dividerShadow: '0px 4px 20px 0px rgba(120, 37, 79, 0.40)',
    dropdownShadow: '0 1px 0 rgba(0, 0, 0, 0.06)',
    lightShadow: '0px 8px 10px rgba(214, 214, 218, 0.2)',
    offWhite: '0px 4px 16px rgba(90, 90, 105, 0.15)',
    onboardingShadow: '0px 4px 20px 0px rgba(35, 31, 32, 0.15)',
    planCard: '0px 0px 20px 0px rgba(0, 0, 0, 0.12)',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.55)'
  },
  sizes: {
    'max-width-desktop': 508
  },
  space: {
    1: '10px',
    2: '16px',
    3: '24px',
    4: '32px',
    6: '48px',
    paddingLeft: 16,
    paddingRight: 16
  },
  styles: {
    input: {
      backgroundColor: 'plainWhite',
      borderColor: 'grey50',
      borderRadius: 4,
      height: 40,
      outline: 'grey50',
      paddingLeft: 20,
      width: ['100%', 'max-width-desktop']
    },
    ul: {
      margin: 0
    }
  }
};
