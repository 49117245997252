export const errorMessages = {
  coverNoteError: {
    title: 'Sorry, something went wrong',
    subtitle: 'Unable to retrieve valid quotations. Please try again later.'
  },
  notAvailable: {
    title: 'We couldn’t generate your quote right now.',
    subtitle: 'Please try again, or contact us at 1-300-13-3338 for assistance.'
  },
  updatePriceError: {
    title: 'Sorry, something went wrong',
    subtitle: 'Unable to retrieve updated prices. Please try again.'
  },
  exceedTime: {
    title: 'Sorry, time limit exceeded',
    subtitle:
      'Please refresh and try again, or contact us at 1-300-13-3338 for assistance.'
  },
  vixExpired: {
    title: 'Sorry, your session has expired',
    subtitle: 'Please start over and try again'
  },
  "We can't proceed with your insurance renewal due to the following reason: vix_transaction_code: Vix Transaction Code has expired.":
    {
      title: 'Sorry, something went wrong',
      subtitle:
        'Please refresh and try again, or contact us at 1-300-13-3338 for assistance.'
    },
  'problemDetail.org.springframework.http.converter.HttpMessageNotReadableException':
    {
      title: 'Sorry, something went wrong',
      subtitle:
        'Please refresh and try again, or contact us at 1-300-13-3338 for assistance.'
    },
  addOnsError: {
    title: 'Sorry, something went wrong',
    subtitle: 'Unable to retrieve add-ons. Please go back and try again.',
    errorModal: { secondBtn: false }
  },
  "We can't proceed with your insurance renewal due to the following reason: Renewal can only be done within 2 months prior to policy expiry date.":
    {
      title: "We can't proceed with your insurance renewal",
      subtitle:
        'Renewal can only be done within 2 months prior to policy expiry date.'
    },
  "We can't proceed with your insurance renewal due to the following reason: Incorrect vehicle registration number. Please check if you have entered the correct information.":
    {
      title: "We can't proceed with your insurance renewal",
      subtitle:
        'Incorrect vehicle registration number. Please check if you have entered the correct information.'
    },
  "We can't proceed with your insurance renewal due to the following reason: Incorrect ID number. Please check if you have entered the correct information.":
    {
      title: "We can't proceed with your insurance renewal",
      subtitle:
        'Incorrect ID number. Please check if you have entered the correct information.'
    },
  "We can't proceed with your insurance renewal due to the following reason: Request timeout error. Please try again.":
    {
      title: "We can't proceed with your insurance renewal",
      subtitle: 'Request timeout error. Please try again.'
    },
  '[503 Service Temporarily Unavailable] during [POST] to [https://stg-insurance-api.finology.com.my/api/v2.4.0/price] [ApixClient#getPrice(CreatePriceRequest,String)]: [<html>\r\n<head><title>503 Service Temporarily Unavailable</title></head>\r\n<body>\r\n<center><h1>503 Service Temporarily Unavailable</h1></center>\r\n<hr><center>nginx</center>\r\n</body>\r\n</html>\r\n]':
    {
      title: 'Sorry, something went wrong',
      subtitle:
        'Please refresh and try again, or contact us at 1-300-13-3338 for assistance.'
    },
  "We can't proceed with your insurance renewal due to the following reason: The insurance for this vehicle has already been renewed.":
    {
      title: "We can't proceed with your insurance renewal",
      subtitle: 'The insurance for this vehicle has already been renewed.'
    },
  "We can't proceed with your insurance renewal due to the following reason: We are unable to retrieve your vehicle information.":
    {
      title: "We can't proceed with your insurance renewal",
      subtitle: 'We are unable to retrieve your vehicle information.'
    },
  "We can't proceed with your insurance renewal due to the following reason: Oops! You've encountered an error. Kindly email us at insurance@loanstreet.com.my or contact us at 03-74515283 for further assistance":
    {
      title: 'Sorry, something went wrong',
      subtitle: 'Kindly contact us at 1-300-13-3338 for further assistance.'
    },
  default: {
    title: 'Sorry, something went wrong',
    subtitle: 'Please refresh page and try again'
  }
};

export const errorExpired = [
  "We can't proceed with your insurance renewal due to the following reason: vix_transaction_code: Vix Transaction Code has expired.",
  'problemDetail.org.springframework.http.converter.HttpMessageNotReadableException'
];

export const unexpectedError = [
  '[503 Service Temporarily Unavailable] during [POST] to [https://stg-insurance-api.finology.com.my/api/v2.4.0/price] [ApixClient#getPrice(CreatePriceRequest,String)]: [<html>\r\n<head><title>503 Service Temporarily Unavailable</title></head>\r\n<body>\r\n<center><h1>503 Service Temporarily Unavailable</h1></center>\r\n<hr><center>nginx</center>\r\n</body>\r\n</html>\r\n]'
];

export const errorPatterns = ['NotMaster', 'errmsg: "not master"'];
