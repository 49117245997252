import { createSlice } from '@reduxjs/toolkit';

import { coverageCodeMapping, Fields } from '../Constant';
import { RESET_ALL, RESET_LOADING, RESET_PRICE } from 'constants/enum';
import { Services } from 'services/api/services';
import {
  addDriverDecorator,
  vixResponseDecorator
} from 'services/decorators/responses';

export const initialState = {
  carAddOn: [
    {
      label: 'Cover for Windscreens, Windows and Sunroof',
      value: 0,
      displayCoverage: 2000,
      checked: false
    },
    {
      label: 'Special Peril Coverage',
      value: 0,
      checked: false
    },
    {
      label: 'Strike, Riot and Civil Commotion',
      value: 0,
      checked: false
    },
    {
      label: 'All Drivers',
      value: 0,
      checked: false
    },
    {
      label: 'Additional Drivers',
      value: 0,
      checked: false,
      additionalDriver: [
        {
          Name: undefined,
          'ID Type': undefined,
          'ID Number': undefined,
          Age: undefined,
          Gender: undefined,
          'Marital Status': 'S',
          Relationship: undefined,
          DOB: undefined,
          Occupation: '01'
        }
      ]
    },
    {
      label: 'Legal Liability to Passengers',
      value: 0,
      checked: false
    },
    {
      label: 'Legal Liability of Passengers for Negligent Acts',
      value: 0,
      checked: false
    }
  ],
  initialSelectedAddOns: [],
  isLoadingAIG: false,
  preselectRejected: undefined
};

export const addOnAIGSlice = createSlice({
  name: 'addOnAIG',
  initialState,
  reducers: {
    setPreselectRejected: (state, action) => {
      state.preselectRejected = action.payload;
    },
    setInitialSelectedAddOns: (state, action) => {
      state.initialSelectedAddOns = action.payload;
    },
    setFieldValue: (state, action) => {
      const {
        index,
        indexDriver,
        item: { key, value }
      } = action.payload;
      if (indexDriver !== undefined) {
        state.carAddOn[index].additionalDriver[indexDriver][key] = value;
      }
    },
    setExtractedDataFromICNumber: (state, action) => {
      const { index, indexDriver, item } = action.payload;
      const { data, status } = item;
      if (status === 'SUCCESS') {
        state.carAddOn[index].additionalDriver[indexDriver][Fields.ID_NUMBER] =
          data.ICNumber;
        state.carAddOn[index].additionalDriver[indexDriver][
          Fields.DOB
        ] = `${data.Year}-${data.Month}-${data.Day}`;
        state.carAddOn[index].additionalDriver[indexDriver][Fields.AGE] =
          data.Age;
        state.carAddOn[index].additionalDriver[indexDriver][Fields.GENDER] =
          data.Gender;
      } else {
        state.carAddOn[index].additionalDriver[indexDriver][Fields.ID_NUMBER] =
          data.ICNumber;
      }
    },
    setDOB: (state, action) => {
      const { index, indexDriver, item } = action.payload;
      const { key, value } = item;
      state.carAddOn[index].DOB.additionalDriver[indexDriver][key] = value;
    },
    setInitialCheckedValue: (state, action) => {
      const lastAddOnIdx = action.payload?.length - 1;
      if (lastAddOnIdx === -1) {
        state.carAddOn.map((item) => {
          item.checked = false;
          item.value = 0;
        });
      }
      const indexArr = [];
      action.payload?.forEach((item, addOnIdx) => {
        const { code, premium } = item;
        const label = coverageCodeMapping[code];
        const index = state.carAddOn.findIndex((item) => item.label === label);
        indexArr.push(index);
        if (index !== -1) {
          state.carAddOn[index].checked = true;
          state.carAddOn[index].value = premium;
        }
        if (lastAddOnIdx === addOnIdx) {
          state.carAddOn.map((item, idx) => {
            if (!indexArr.includes(idx)) {
              item.checked = false;
              item.value = 0;
            }
          });
        }
      });
      state.initialSelectedAddOns = state.carAddOn.map((item) => item.checked);
    },
    setCheckedValue: (state, action) => {
      const index = action.payload;
      if (state.carAddOn[index]) {
        state.carAddOn[index].checked = !state.carAddOn[index].checked;
      }
    },
    setCoverageValue: (state, action) => {
      const { index, value } = action.payload;
      const valueWithoutComma = value?.replace(/,/g, '');
      if (state.carAddOn[index]) {
        state.carAddOn[index].displayCoverage = +valueWithoutComma;
      }
    },
    setShowBMW: (state, action) => {
      state.showBMW = action.payload;
      state.carAddOn[3].excluded = action.payload;
      state.carAddOn[4].excluded = action.payload;
    },
    setUpdatePrice: (state, action) => {
      const { index, label, value } = action.payload;
      if (state.carAddOn[index].label === label) {
        state.carAddOn[index].checked = true;
        state.carAddOn[index].value = +value;
      }
    },
    setInitialPrice: (state, action) => {
      const { coverCode, coverPremium } = action.payload;
      const label = coverageCodeMapping[coverCode];
      const index = state.carAddOn.findIndex((item) => item.label === label);
      if (index !== -1) {
        state.carAddOn[index].checked = true;
        state.carAddOn[index].value = +coverPremium;
      }
    },
    setAddDriver: (state, action) => {
      const { driver, index } = action.payload;
      state.carAddOn[index]?.additionalDriver.push(driver);
    },
    setRemoveDriver: (state, action) => {
      const { index, indexDriver } = action.payload;
      state.carAddOn[index]?.additionalDriver.splice(indexDriver, 1);
    },
    setLoadingForAIG: (state, action) => {
      const { isLoading } = action.payload;
      state.isLoadingAIG = isLoading;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET_ALL, () => initialState)
      .addCase(RESET_PRICE, () => initialState)
      .addCase(RESET_LOADING, (state) => {
        state.isLoadingAIG = false;
      })
      .addMatcher(
        Services.endpoints.getVehicleInformation.matchFulfilled,
        (state, { payload }) => {
          const { applicationInformation, vehicleDetails } =
            vixResponseDecorator(payload);
          if (applicationInformation?.extra?.addons?.length > 0) {
            const code = applicationInformation.extra.addons.find(
              (addon) => addon.code === 'C006'
            );
            if (code) {
              state.carAddOn.forEach((carAddOn) => {
                const label = coverageCodeMapping.C006;
                if (carAddOn.label === label) {
                  carAddOn.displayCoverage = code.coverageRequestedValue;
                }
              });
            }
          }
          if (applicationInformation?.extra?.drivers) {
            state.carAddOn[4].additionalDriver = addDriverDecorator(
              applicationInformation.extra.drivers
            );
          }
        }
      );
  }
});

export const {
  setAddDriver,
  setCheckedValue,
  setCoverageValue,
  setExtractedDataFromICNumber,
  setFieldValue,
  setInitialCheckedValue,
  setInitialPrice,
  setInitialSelectedAddOns,
  setLoadingForAIG,
  setPreselectRejected,
  setRemoveDriver,
  setShowBMW,
  setUpdatePrice
} = addOnAIGSlice.actions;

export default addOnAIGSlice.reducer;
