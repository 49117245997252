import { createSlice } from '@reduxjs/toolkit';

import { CoverageTypes, Fields } from '../Constant';
import { RESET_ALL, UPDATE_VIX } from 'constants/enum';
import { Services } from 'services/api/services';
import {
  priceUpdateResponseDecorator,
  vixResponseDecorator
} from 'services/decorators/responses';

export const initialState = {
  [Fields.COVERAGE_TYPE]: CoverageTypes.COMPREHENSIVE.value,
  [Fields.SUM_INSURED]: undefined
};

export const coverageType = {
  Comprehensive: CoverageTypes.COMPREHENSIVE.value,
  'Third Party, Fire & Theft': CoverageTypes.THIRD_PARTY_FIRE_THEFT.value
};

export const coverageSumInsuredSlice = createSlice({
  name: 'coverageSumInsured',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      state[Fields.SUM_INSURED] = action.payload;
    },
    setButtonType: (state, action) => {
      state[Fields.COVERAGE_TYPE] =
        coverageType[action.payload] || action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET_ALL, () => initialState)
      .addCase(UPDATE_VIX, (state, action) => {
        state[Fields.COVERAGE_TYPE] = action.payload?.extra?.coverage_type;
        state[Fields.SUM_INSURED] = action.payload?.extra?.sum_insured;
      })
      .addMatcher(
        Services.endpoints.getVehicleInformation.matchFulfilled,
        (state, { payload }) => {
          const { applicationInformation, vehicleDetails } =
            vixResponseDecorator(payload);
          state[Fields.SUM_INSURED] = vehicleDetails?.ism_insured_value;
          state[Fields.COVERAGE_TYPE] =
            applicationInformation?.extra?.coverage_type ||
            CoverageTypes.COMPREHENSIVE.value;
        }
      )
      .addMatcher(
        Services.endpoints.updateSelectedAddOns.matchFulfilled,
        (state, action) => {
          const { response } = action.payload;
          const {
            aig: { aigData },
            zurich: { zurichData }
          } = priceUpdateResponseDecorator(response);
          if (zurichData) {
            state[Fields.SUM_INSURED] = zurichData?.insurer_sum_insured_value;
          }
          if (aigData) {
            state[Fields.SUM_INSURED] = aigData?.insurer_sum_insured_value;
          }
        }
      );
  }
});

export const { setButtonType, setFieldValue } = coverageSumInsuredSlice.actions;

export default coverageSumInsuredSlice.reducer;
