import { createSlice } from '@reduxjs/toolkit';

import { RESET_ALL, RESET_PRICE } from 'constants/enum';
import { Services } from 'services/api/services';
import { priceUpdateResponseDecorator } from 'services/decorators/responses';

const initialState = {};

export const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    setZurichData: (state, action) => {
      state.ZURICH = {
        ...state.ZURICH,
        ...action.payload
      };
    },
    setAIGData: (state, action) => {
      state.AIG = {
        ...state.AIG,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET_ALL, () => initialState)
      .addCase(RESET_PRICE, () => initialState)
      .addMatcher(
        Services.endpoints.chooseSumInsured.matchFulfilled,
        (state, action) => {
          const data = action.payload;
          const {
            aig: { aigData },
            zurich: { zurichData }
          } = priceUpdateResponseDecorator(data?.response);
          state.ZURICH = {
            ...state.ZURICH,
            ...zurichData
          };
          state.AIG = {
            ...state.AIG,
            ...aigData
          };
        }
      )
      .addMatcher(
        Services.endpoints.updateSelectedAddOns.matchFulfilled,
        (state, action) => {
          const data = action.payload;
          const {
            aig: { aigData },
            zurich: { zurichData }
          } = priceUpdateResponseDecorator(data?.response);
          state.ZURICH = {
            ...state.ZURICH,
            ...zurichData
          };
          state.AIG = {
            ...state.AIG,
            ...aigData
          };
        }
      );
  }
});

export const { setAIGData, setZurichData } = priceSlice.actions;

export default priceSlice.reducer;
