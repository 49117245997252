import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { initialTokenFetch, refreshToken } from './auth';
import { insurerType, RESET_LOADING } from 'constants/enum';
import {
  setInitialCheckedValue as setInitialCheckedValueAIG,
  setLoadingForAIG,
  setPreselectRejected as setPreselectRejectedAIG
} from 'container/screens/AddOnAIG/Logic/AddOnAIG.reducer';
import {
  setInitialCheckedValue,
  setLoadingForZurich,
  setPreselectRejected
} from 'container/screens/AddOnZurich/Logic/AddOnZurich.reducer';
import { setFieldValue } from 'container/screens/CoverageSumInsured/Logic/CoverageSumInsured.reducer';
import { setTokenExpired } from 'store/state/baseSlice';
import { getCookie } from 'util/cookieStorage';
import { logError } from 'util/helper';

const baseQuery = fetchBaseQuery({ baseUrl: '/services' });

const baseQueryWithTokenRefresh = async (args, api, extraOptions) => {
  const refreshTokenValue = getCookie('refreshToken');
  const expiresIn = Number(getCookie('expiresIn'));
  const refreshExpiresIn = Number(getCookie('refreshExpiresIn'));

  const currentTime = Date.now() / 1000;
  if (
    !refreshTokenValue ||
    (refreshTokenValue && currentTime > refreshExpiresIn)
  ) {
    try {
      const data = await api.dispatch(initialTokenFetch.initiate()).unwrap();
      args.headers = new Headers(args.headers);
      args.headers.set('Authorization', `Bearer ${data.access_token}`);
      args.headers.set('Content-Type', 'application/json');
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error) {
      logError(error, 'Error fetching initial token');
    }
  } else if (refreshTokenValue && currentTime > expiresIn - 60) {
    try {
      const data = await api
        .dispatch(refreshToken.initiate(refreshTokenValue))
        .unwrap();
      args.headers = new Headers(args.headers);
      args.headers.set('Authorization', `Bearer ${data.access_token}`);
      args.headers.set('Content-Type', 'application/json');
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error) {
      api.dispatch(setTokenExpired(true));
      api.dispatch({ type: RESET_LOADING });
      logError(error, 'Error refreshing token');
    }
  } else {
    args.headers = new Headers(args.headers);
    args.headers.set('Authorization', `Bearer ${getCookie('accessToken')}`);
    args.headers.set('Content-Type', 'application/json');

    return baseQuery(args, api, extraOptions);
  }
};

export const Services = createApi({
  reducerPath: 'Services',
  baseQuery: baseQueryWithTokenRefresh,
  endpoints: (builder) => ({
    checkInsuranceEligibility: builder.mutation({
      query: (data) => ({
        url: '/onboarding/',
        method: 'POST',
        body: data
      }),
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        try {
          const resp = await queryFulfilled;
          const sumInsured =
            resp?.data?.vix_response?.response?.vehicle_details
              ?.ism_insured_value;
          dispatch(setFieldValue(sumInsured));
        } catch (error) {
          logError(error, 'check landing page eligibility error');
        }
      }
    }),
    chooseSumInsured: builder.mutation({
      query: (data) => {
        const { application_id: applicationId } = data;
        return {
          url: `/onboarding/${applicationId}/quotation`,
          method: 'POST',
          body: data
        };
      },
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        if (data.insurer_code[0] === 'ZURICH') {
          try {
            dispatch(setLoadingForZurich({ isLoading: true }));
            const priceData = await queryFulfilled;
            const selectedAddOnsZurich =
              priceData?.data?.response?.ZURICH?.extra_coverage?.extra_block;
            const mappedAddOnsZurich = selectedAddOnsZurich?.map((addOn) => {
              return {
                code: addOn[0].cover_code,
                availability: addOn[0].cover_availability,
                premium: addOn[0].cover_premium
              };
            });
            dispatch(setInitialCheckedValue(mappedAddOnsZurich));
          } catch (error) {
            dispatch(setPreselectRejected(true));
            logError(error, 'onboarding preselected add ons zurich error');
          } finally {
            dispatch(setLoadingForZurich({ isLoading: false }));
          }
        }
        if (data.insurer_code[0] === 'AIG') {
          dispatch(setLoadingForAIG({ isLoading: true }));
          try {
            const priceData = await queryFulfilled;
            const selectedAddOnsAIG =
              priceData?.data?.response?.AIG?.extra_coverage?.extra_block;
            const mappedAddOnsAIG = selectedAddOnsAIG?.map((addOn) => {
              return {
                code: addOn[0].cover_code,
                availability: addOn[0].cover_availability,
                premium: addOn[0].cover_premium
              };
            });
            dispatch(setInitialCheckedValueAIG(mappedAddOnsAIG));
          } catch (error) {
            dispatch(setPreselectRejectedAIG(true));
            logError(error, 'onboarding preselected add ons error');
          } finally {
            dispatch(setLoadingForAIG({ isLoading: false }));
          }
        }
      }
    }),
    updateSelectedAddOns: builder.mutation({
      query: (data) => {
        const { insurer, quotationNo, ...updatePrice } = data;
        return {
          url: `/quotation/${quotationNo}`,
          method: 'PATCH',
          body: updatePrice
        };
      },
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        if (data?.insurer?.toLowerCase() === insurerType.ZURICH) {
          dispatch(setLoadingForZurich({ isLoading: true }));
          try {
            const priceData = await queryFulfilled;
            if (priceData?.data?.response?.ZURICH) {
              const selectedAddOnsZurich =
                priceData?.data?.response?.ZURICH?.extra_coverage?.extra_block;
              const mappedAddOnsZurich = selectedAddOnsZurich?.map((addOn) => {
                return {
                  code: addOn[0].cover_code,
                  availability: addOn[0].cover_availability,
                  premium: addOn[0].cover_premium
                };
              });
              dispatch(setInitialCheckedValue(mappedAddOnsZurich));
            }
          } catch (error) {
            dispatch(setPreselectRejected(true));
            logError(error, 'update selected add ons zurich error');
          } finally {
            dispatch(setLoadingForZurich({ isLoading: false }));
          }
        }
        if (data?.insurer?.toLowerCase() === insurerType.AIG) {
          dispatch(setLoadingForAIG({ isLoading: true }));
          try {
            const priceData = await queryFulfilled;
            if (priceData?.data?.response?.AIG) {
              const selectedAddOnsAIG =
                priceData?.data?.response?.AIG?.extra_coverage?.extra_block;
              const mappedAddOnsAIG = selectedAddOnsAIG?.map((addOn) => {
                return {
                  code: addOn[0].cover_code,
                  availability: addOn[0].cover_availability,
                  premium: addOn[0].cover_premium
                };
              });
              dispatch(setInitialCheckedValueAIG(mappedAddOnsAIG));
            }
          } catch (error) {
            dispatch(setPreselectRejectedAIG(true));
            logError(error, 'update selected add ons error');
          } finally {
            dispatch(setLoadingForAIG({ isLoading: false }));
          }
        }
      }
    }),
    requestOrder: builder.mutation({
      query: (data) => {
        const { quotationNo, ...requestOrder } = data;
        return {
          url: `/quotation/${quotationNo}/order`,
          method: 'POST',
          body: requestOrder
        };
      }
    }),
    coverNoteGeneration: builder.mutation({
      query: (data) => {
        const { orderNo, ...coverNote } = data;
        return {
          url: `/order/${orderNo}/cover_note`,
          method: 'POST',
          body: coverNote
        };
      }
    }),
    resumeJourney: builder.mutation({
      query: (data) => {
        const { applicationId } = data;
        return {
          url: `${process.env.REACT_APP_SDM_URL}/services/onboarding/api/application/${applicationId}`,
          method: 'GET'
        };
      }
    }),
    saveApplication: builder.mutation({
      query: (data) => {
        return {
          url: `${process.env.REACT_APP_SDM_URL}/services/onboarding/api/application`,
          method: 'PUT',
          body: data
        };
      }
    }),
    getVehicleInformation: builder.mutation({
      query: (applicationId) => {
        return {
          url: `/onboarding/${applicationId}`,
          method: 'GET'
        };
      }
    })
  })
});

export const {
  useCheckInsuranceEligibilityMutation,
  useChooseSumInsuredMutation,
  useCoverNoteGenerationMutation,
  useGetVehicleInformationMutation,
  useRequestOrderMutation,
  useResumeJourneyMutation,
  useSaveApplicationMutation,
  useUpdateSelectedAddOnsMutation
} = Services;
