import { createSlice } from '@reduxjs/toolkit';

import { Fields } from '../Constant';
import { cityStatePostalCodes } from 'constants/cityState';
import { RESET_ALL, UPDATE_VIX } from 'constants/enum';

const findStateAndCity = (postcode) => {
  for (const state in cityStatePostalCodes) {
    for (const city in cityStatePostalCodes[state]) {
      if (cityStatePostalCodes[state][city].includes(postcode)) {
        return { city, state };
      }
    }
  }
  return null;
};

export const initialState = {
  [Fields.COMPANY_NAME]: undefined,
  [Fields.FULL_NAME]: undefined,
  [Fields.MOBILE_NUMBER]: undefined,
  [Fields.EMAIL]: undefined,
  [Fields.MARITAL_STATUS]: undefined,
  [Fields.POSTCODE]: undefined,
  [Fields.DOB]: undefined,
  [Fields.GENDER]: undefined,
  [Fields.STATE]: undefined,
  [Fields.CITY]: undefined
};

export const PersonalDetailSlice = createSlice({
  name: 'personalDetails',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetCityState: (state) => {
      state[Fields.CITY] = undefined;
      state[Fields.STATE] = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET_ALL, () => initialState)
      .addCase(UPDATE_VIX, (state, action) => {
        const { city, state: cityState } = findStateAndCity(
          action.payload?.extra?.postcode
        ) || { city: undefined, state: undefined };
        state[Fields.FULL_NAME] = action.payload?.extra?.name;
        state[Fields.MOBILE_NUMBER] =
          action.payload?.extra?.mobile_no?.replace(/^0/, '') ||
          action.payload?.extra?.mobile?.replace(/^0/, '');
        state[Fields.EMAIL] = action.payload?.extra?.email;
        state[Fields.MARITAL_STATUS] =
          action.payload?.extra?.marital_status ||
          action.payload?.extra?.marital;
        state[Fields.POSTCODE] = action.payload?.extra?.postcode;
        state[Fields.DOB] = action.payload?.extra?.dob;
        state[Fields.GENDER] = action.payload?.extra?.gender;
        state[Fields.STATE] = cityState;
        state[Fields.CITY] = city;
        state[Fields.COMPANY_NAME] = action.payload?.extra?.company_name;
      });
  }
});

export const { resetCityState, setFieldValue } = PersonalDetailSlice.actions;

export default PersonalDetailSlice.reducer;
